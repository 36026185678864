import React from 'react'

import './AiComputationModal.css'

const AiComputationModal = () => (
  <div className="AiComputationModal x5">
    <div className="AiComputationModal-inner">
      Ai is thinking...
    </div>
  </div>
)

export default AiComputationModal
